:root {
  --color-primary: #7B6154;
  --color-secondary: #343a40;
  --color-bg: #f5f5f5;
  --color-font: #2c2c2c;
  --color-font-light: #9A9A9A;
  --header-top-color: transparent;
  --header-content-color: var(--color-bg);
  --wpp-color: rgb(0, 184, 76);
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
  --header-height: 120px;
  color: var(--color-font);
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

h2 {
  font-size: 2rem;
  text-align: center;
}

h3 {
  font-size: 2.75rem;
  text-align: center;
}

.section-header::after {
  content: "";
  display: block;
  width: 100px;
  height: 4px;
  margin: 2rem auto 3rem auto;
  background-color: var(--color-bg);
}

a {
  color: var(--color-font);
}

.wpp-button {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  bottom: 0;
  right: 0;
  background-color: var(--wpp-color);
  border-radius: 50%;
  margin: 1rem;
  z-index: 100;
}

.wpp-icon {
  background-image: url(../assets/whatsapp-logo.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 50%;
  height: 50%;
  display: block;
}

.pure-carousel {
  position: relative;
  padding: 0 24px;
}

.pure-carousel .carousel__dot-group {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pure-carousel .carousel__dot {
  width: 16px;
  height: 16px;
  margin: 0 5px;
  background-color: var(--color-bg);
  border-radius: 50%;
  transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45);
}

.pure-carousel .carousel__dot:hover {
  transform: scale(1.2);
}

.pure-carousel .carousel__dot--selected {
  transform: scale(1.2);
}

.pure-carousel .carousel__back-button, .pure-carousel .carousel__next-button {
  position: absolute;
  /* background-color: rgba(255,255,255, 0.5); */
  top: 50%;
  width: 20px;
  height: 20px;
}

.pure-carousel .carousel__back-button:before, .pure-carousel .carousel__next-button:before,
.pure-carousel .carousel__back-button:after, .pure-carousel .carousel__next-button:after {
  content: "";
  position: absolute;
  height: 100%;
  /* border-radius: var(--organic-arrow-border-radius); */
  width: 4px;
  background-color: var(--color-bg);
}

.pure-carousel .carousel__back-button.alt-color:before,
.pure-carousel .carousel__next-button.alt-color:before,
.pure-carousel .carousel__back-button.alt-color:after,
.pure-carousel .carousel__next-button.alt-color:after {
  background-color: var(--color-primary);
}

.pure-carousel .carousel__back-button:before, .pure-carousel .carousel__next-button:before {
  transform-origin: 100% 100% 0;
  top: -50%;
  transform: rotate(-45deg);
}

.pure-carousel .carousel__back-button:after, .pure-carousel .carousel__next-button:after {
  transform-origin: 100% 0% 0;
  top: 50%;
  transform: rotate(45deg);
}

.pure-carousel .carousel__back-button {
  left:0;
  transform: rotate(180deg) translateX(100%);
}

.pure-carousel .carousel__next-button {
  right: 0;
  transform: translateX(100%);
}
